<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="suggestion"
      sort-by="creationDate"
      :sort-desc="true"
      :must-sort="true"
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Sugestões e Reclamações</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />
          <v-spacer />
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="viewItem(item)"
              v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>Visualizar</span>
        </v-tooltip>
      </template>
      <template #item.creationDate="{ item }">
        <p>{{ item.creationDate | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <view-suggestion
        v-if="dialog"
        :populate-with="suggestionToView"
        @close-dialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import Suggestion from '../../../services/suggestion.service';

export default {
  name: 'Suggestion',
  components: {
    'view-suggestion': () => import('./View'),
  },
  data: () => ({
    headers: [
      {
        text: 'Tipo',
        sortable: false,
        value: 'type',
      },
      {
        text: 'Autor',
        sortable: false,
        value: 'user',
      },
      {
        text: 'Cidade',
        sortable: true,
        value: 'city',
      },
      {
        text: 'Categoria',
        sortable: true,
        value: 'category',
      },
      {
        text: 'Data de Criação',
        value: 'creationDate',
        sortable: true,
      },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    suggestion: [],
    suggestionToView: {},
    search: '',
    dialog: false,
  }),
  async created() {
    await this.loadSuggestion();
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.suggestionToView = {};
    },
    viewItem(item) {
      this.suggestionToView = item;
      this.dialog = true;
    },
    async loadSuggestion() {
      const data = await Suggestion.getSuggestion();
      this.suggestion = data;
    },
  },
};
</script>
